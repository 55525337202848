import React, { useState } from "react";

import { Helmet } from "react-helmet";

import { apiFetch } from "../../../../../../apiBindings";
import pushToAnalytics from "../../../../../../utils/analytics";
import StorageHandler from "../../../../../../utils/StorageHandler";
import Container from "../../../../Container";
import DatePicker from "../../../../forms/DatePicker";
import FormError from "../../../../forms/FormError";
import NewInput from "../../../../forms/NewInput";
import Headline from "../../../../Headline";
import LoadingIndicator from "../../../../LoadingIndicator";
import Margin from "../../../../Margin";
import Button from "../../../../NewForms/Button";
import { UncontrolledNotification as Notification } from "../../../../Notification";

const GasCounterForm = ({ params }) => {
    const storage = new StorageHandler();

    /**
     * If the user didn't sent something or the last sending ist older or equals 5 minutes ago,
     * he can send again.
     *
     * @param {*} timestamp
     */
    const canSend = (timestamp) => {
        if (timestamp === null) {
            return true;
        }
        return Math.floor((Date.now() - parseInt(timestamp)) / 1000 / 60) >= 5;
    };

    const [sending, setSending] = useState(false);
    const [sendingError, setSendingError] = useState(false);
    const [errors, setErrors] = useState([]);
    const [customerNumber, setCustomerNumber] = useState(params.customerNumber);
    const [counterNumber, setCounterNumber] = useState(params.counterNumber);
    const [counterLevel, setCounterLevel] = useState("");
    const [readingDate, setReadingDate] = useState("");
    const [emailSent, setEmailSent] = useState(
        !canSend(storage.get("gas-counter-level:contact-form:sent-at", null))
    );

    const onSubmit = (event) => {
        event.preventDefault();

        if (errors.length === 0) {
            // Check if the fields are valid
            if (
                customerNumber === "" ||
                customerNumber === "" ||
                counterLevel === "" ||
                readingDate === ""
            ) {
                const localErrors = [...errors];
                if (customerNumber === "") {
                    localErrors.push("customerNumber");
                }
                if (counterNumber === "") {
                    localErrors.push("counterNumber");
                }
                if (counterLevel === "") {
                    localErrors.push("counterLevel");
                }
                if (readingDate === "") {
                    localErrors.push("readingDate");
                }
                setErrors(localErrors);
                return;
            }
            setSending(true);
            void apiFetch("/gas-counting", {
                method: "post",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: JSON.stringify({
                    customerNumber,
                    counterNumber,
                    counterLevel,
                    readingDate,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        storage.set(
                            "gas-counter-level:contact-form:sent-at",
                            Date.now()
                        );
                        storage.set("app:uid", data.appUid);
                        setEmailSent(true);
                        setSendingError(false);
                        pushToAnalytics("contact-form:sent", {
                            form: "gas-counter-level:contact-form",
                        });
                    } else {
                        setSendingError(true);
                    }
                    setSending(false);
                });
        }
    };

    const onChangeCounterLevel = (event) => {
        setCounterLevel(event.target.value);
        if (event.target.value !== "") {
            clearError("message");
        }
    };

    const onChangeCounterNumber = (event) => {
        setCounterNumber(event.target.value);
        if (event.target.value !== "") {
            clearError("message");
        }
    };

    const onChangeCustomerNumber = (event) => {
        setCustomerNumber(event.target.value);
        if (event.target.value !== "") {
            clearError("message");
        }
    };

    const onChangeReadingDate = (value) => {
        setReadingDate(value);
        if (value.length > 0) {
            clearError("readingDate");
        }
    };

    const addFormError = (key) => {
        if (!errors.includes(key)) {
            setErrors([...errors, key]);
        }
    };

    const hasErrorAt = (key) => {
        return errors.filter((item) => item === key).length > 0;
    };

    /**
     * Remove an element from the errors.
     *
     * @param {*} key
     */
    const clearError = (key) => {
        setErrors(errors.filter((item) => item !== key));
    };

    return (
        <>
            <Helmet>
                <title>Zählerstandformular Gas</title>
                <meta name="description" content="Zählerstandformular Gas" />
            </Helmet>

            <Container>
                <div className="box">
                    <Headline>Zählerstand für Gas eingeben</Headline>
                    <Margin top="30">
                        {sendingError ? (
                            <Notification>
                                <Headline>Entschulding</Headline>
                                <p>
                                    Es ist leider ein Fehler aufgetreten.
                                    Versuchen Sie es bitte in Kürze erneut.
                                </p>
                                <Button onClick={() => setSendingError(false)}>
                                    Zum Formular
                                </Button>
                            </Notification>
                        ) : sending ? (
                            <Notification>
                                <LoadingIndicator>
                                    Deine Nachricht wird übermittelt. Habe bitte
                                    einen Moment Geduld.
                                </LoadingIndicator>
                            </Notification>
                        ) : emailSent ? (
                            <Notification>
                                <Headline>Vielen Dank</Headline>
                                <p>
                                    Wir haben deinen Zählerstand für Gas
                                    erhalten
                                </p>
                            </Notification>
                        ) : (
                            <>
                                <form onSubmit={onSubmit}>
                                    <NewInput
                                        value={customerNumber}
                                        onChange={onChangeCustomerNumber}
                                        placeholder="Kundennummer"
                                        validation="hoyerGasElectricityCustomerNumber"
                                        onError={() =>
                                            addFormError("customerNumber")
                                        }
                                        onErrorSolved={() =>
                                            clearError("customerNumber")
                                        }
                                        forceError={hasErrorAt(
                                            "customerNumber"
                                        )}
                                        required={true}
                                    />
                                    <NewInput
                                        value={counterNumber}
                                        onChange={onChangeCounterNumber}
                                        placeholder="Zählernummer"
                                        validation="counterNumber"
                                        onError={() =>
                                            addFormError("counterNumber")
                                        }
                                        onErrorSolved={() =>
                                            clearError("counterNumber")
                                        }
                                        forceError={hasErrorAt("counterNumber")}
                                        required={true}
                                    />
                                    <NewInput
                                        value={counterLevel}
                                        onChange={onChangeCounterLevel}
                                        placeholder="Zählerstand"
                                        validation="counterLevelInt"
                                        onError={() =>
                                            addFormError("counterLevel")
                                        }
                                        onErrorSolved={() =>
                                            clearError("counterLevel")
                                        }
                                        forceError={hasErrorAt("counterLevel")}
                                    />
                                    <DatePicker
                                        label="Ablesedatum"
                                        onChange={onChangeReadingDate}
                                        maxDate={new Date()}
                                        value={readingDate}
                                    />
                                    {hasErrorAt("readingDate") && (
                                        <FormError message="Bitte geben Sie ein Ablesedatum an." />
                                    )}
                                    <Button
                                        fullWidth
                                        data-gtag="gas-counter-level:contact-form"
                                        type="submit"
                                    >
                                        SENDEN
                                    </Button>
                                </form>
                            </>
                        )}
                    </Margin>
                </div>
            </Container>
        </>
    );
};

export default GasCounterForm;
